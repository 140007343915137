import { Button, Fade, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';

const DevProfile = () => {
  const navigate = useNavigate();

  const [showContact, setShowContact] = useState(false);

  const animate = useSelector((state: RootState) => state.ui.animate.homepage);

  const handleContact = () => {
    navigate('/contact');
  };

  const handleResume = () => {
    window.open('/resume/zacsalazar_cv.pdf', '_blank');
  };

  useEffect(() => {
    setTimeout(() => {
      setShowContact(true);
    }, 2000);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Bringing over 14 years of diverse experience, I'm a seasoned Full
          Stack Developer specializing in Web Development and Software
          Engineering.
          <br />
          <br />
          I excel in crafting web systems from scratch and enhancing legacy
          code. As a dynamic Freelancer, I bring both independent excellence and
          collaborative prowess to every project.
          <br />
          <br />
          Seeking a long-term role as a Software Engineer, I'm committed to
          continuous learning and staying at the forefront of emerging
          technologies.
          <br />
          <br />
          Let's build something amazing together.
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop="50px" />
      <Grid item>
        <Button variant="outlined" onClick={handleResume}>
          View Resume
        </Button>
      </Grid>
      {animate ? (
        <Fade in={showContact} timeout={800}>
          <Grid item>
            <Button variant="contained" onClick={handleContact}>
              Let's connect
            </Button>
          </Grid>
        </Fade>
      ) : (
        <Grid item>
          <Button variant="contained" onClick={handleContact}>
            Let's connect
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export { DevProfile };
