import { FC, useEffect } from 'react';
import { PageEntrance } from '../loaders';
import { SkillSlider } from '../components/SkillSlider';
import { Grid } from '@mui/material';
import { SkillStats } from '../resources/types';
import { Reviews } from '../components/Reviews';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setSkillsPageLoaded } from '../store/reducers';

const Skills: FC = () => {
  const dispatch = useDispatch();
  const animate = useSelector(
    (state: RootState) => state.ui.animate.skillspage
  );

  const skills: SkillStats[] = [
    {
      skill: 'Javascript',
      level: 100,
    },
    {
      skill: 'Typescript',
      level: 90,
    },
    {
      skill: 'React',
      level: 100,
    },
    {
      skill: 'Node',
      level: 80,
    },

    {
      skill: 'SQL',
      level: 70,
    },
    {
      skill: 'CSS',
      level: 100,
    },

    {
      skill: 'Material UI',
      level: 100,
    },

    {
      skill: 'Git',
      level: 100,
    },
    {
      skill: 'Firebase',
      level: 100,
    },
    {
      skill: 'PHP',
      level: 100,
    },

    {
      skill: 'Jest',
      level: 80,
    },
    {
      skill: 'ReactNative',
      level: 75,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSkillsPageLoaded(true));
    }, 1000);
  }, [dispatch]);

  return (
    <PageEntrance title="skills" animate={animate}>
      <Grid container spacing={2}>
        {skills.map((skill) => (
          <Grid item xs={4} md={3} key={skill.skill}>
            <SkillSlider {...skill} animate={!animate} />
          </Grid>
        ))}

        <Grid item xs={12} />

        <Grid item xs={12} md={12}>
          <Reviews />
        </Grid>
      </Grid>
    </PageEntrance>
  );
};

export default Skills;
