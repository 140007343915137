import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UiInitialState {
  isLoading: boolean;
  displayModal: boolean;
  animate: {
    homepage: boolean;
    skillspage: boolean;
    contactpage: boolean;
    experiencepage: boolean;
  };
}

const initialState: UiInitialState = {
  isLoading: true,
  displayModal: false,
  animate: {
    homepage: true,
    skillspage: true,
    contactpage: true,
    experiencepage: true,
  },
};
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDisplayModal: (state, action: PayloadAction<boolean>) => {
      state.displayModal = action.payload;
    },
    setHomePageLoaded: (state, action: PayloadAction<boolean>) => {
      state.animate.homepage = !action.payload;
    },
    setExperiencePageLoaded: (state, action: PayloadAction<boolean>) => {
      state.animate.experiencepage = !action.payload;
    },
    setSkillsPageLoaded: (state, action: PayloadAction<boolean>) => {
      state.animate.skillspage = !action.payload;
    },
    setContactPageLoaded: (state, action: PayloadAction<boolean>) => {
      state.animate.contactpage = !action.payload;
    },
  },
});

export const {
  setIsLoading,
  setDisplayModal,
  setHomePageLoaded,
  setSkillsPageLoaded,
  setContactPageLoaded,
  setExperiencePageLoaded,
} = uiSlice.actions;
const UiReducer = uiSlice.reducer;

export { UiReducer };
