import { FC, useEffect } from 'react';
import { PageEntrance } from '../loaders';
import { Job } from '../components/Job';
import { Grid } from '@mui/material';
import { experience } from '../content/';
import { ScrollWrap } from '../components/ScrollWrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setExperiencePageLoaded } from '../store/reducers';

const Experience: FC = () => {
  const dispatch = useDispatch();
  const animate = useSelector(
    (state: RootState) => state.ui.animate.experiencepage
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(setExperiencePageLoaded(true));
    }, 1000);
  }, [dispatch]);

  return (
    <PageEntrance title="experience" animate={animate}>
      <ScrollWrap>
        <Grid container spacing={2}>
          {experience.map((job) => {
            return (
              <Grid item xs={12} key={job.company}>
                <Job {...job} />
              </Grid>
            );
          })}
        </Grid>
      </ScrollWrap>
    </PageEntrance>
  );
};

export default Experience;
