import { Experience } from '../resources/types/Experience';

// put latest on top
const experience: Experience[] = [
  {
    title: 'Tech Lead',
    dates: 'Aug 2024 - Mar 2025',
    company: 'Commandeur Enterprises',
    location: 'Docklands, VIC (Remote)',
    description:
      'Commandeur Enterprises is the main company operating businesses in various industries such as Pool Compliance, Digital Marketing, Business Consultancy, Real-estate, Home decluttering, etc.',
    responsibilities: [
      'Transitioning their data from spreadsheets to a structured and scalable CRM solution.',
      'Analyzing current manual operations and streamlining them through automation.',
      'Improving collaboration and efficiency through structured project and task management.',
      'Reducing expenses through AI-driven automation with chatbots and voice AI.',
    ],
    stack: [
      'React',
      'Node',
      'TypeScript',
      'OpenAI',
      'WordPress',
      'Google Cloud',
    ],
    logo: '/company-logo/dfu.png',
    screenshot: '/screenshots/dfu.jpg',
  },
  {
    title: 'Software Engineer',
    dates: 'July 2023 - Aug 2024',
    company: 'HoneyGrid',
    location: 'Heber City, Utah (Remote)',
    description:
      'HoneyGrid is a marketing software company that developed an app for precise geotargeting.',
    responsibilities: [
      'Investigate and prototype new tech for tech stack.',
      'Develop User Interface based on design and specs for both mobile and web.',
      'Create Firebase cloud functions to be consumed by both mobile and web application.',
      'Write tests using Vitest for web, and Jest for both mobile and backend to ensure code quality and requirement confirmation.',
      'Create Firestore functions for database functionality.',
      'Create third party integrations for partnership platform and marketing website',
    ],
    stack: [
      'React',
      'Node',
      'TypeScript',
      'Firebase',
      'MaterialUI',
      'Storybook',
      'Jest',
    ],
    logo: '/company-logo/honeygrid.jpeg',
    screenshot: '/screenshots/honeygrid-min.png',
  },
  {
    title: 'Chief Technology Officer',
    dates: 'June 2021 - Aug 2023',
    company: 'WorkflowU',
    location: 'Sydney, NSW (Remote)',
    description:
      'WorkflowU is a business management software for service-based jobs, simplifying tasks from quoting to invoicing, scheduling, and team communication.',
    stack: [
      'MongoDB',
      'Express',
      'React',
      'Node',
      'Google Cloud',
      'MaterialUI',
      'Jest',
    ],
    responsibilities: [
      'CTO overseeing project management and full-stack software development utilizing the MERN stack.',
      'Integral involvement in daily tasks, focusing on front-end aspects, and strategizing the technology employed for development. Specialization in UI/UX, complemented by occasional involvement in back-end tasks in collaboration with the development team.',
      'Actively serving as the SCRUM master, ensuring the timely and efficient management of deliverables.',
    ],
    logo: '/company-logo/workflowu.png',
    screenshot: '/screenshots/workflowu-min.png',
  },
  {
    title: 'Software Engineer',
    dates: 'March 2020 - December 2020',
    company: 'MVP Mailhouse',
    location: 'Heber City, Utah (Remote)',
    description: `MVP Mailhouse, a leading direct-mailing company, harnesses extensive experience in the dental and technology industries. Pioneering a tech-driven approach to patient acquisition through direct mail, we utilize cutting-edge tools such as Map IQ, Design Lab, and Call Pro to elevate practices into standout MVPs.
      `,
    responsibilities: [
      'Senior Software Engineer at MVP Mailhouse, adirect mail company with specialized tech for da targeting and analytics.',
      'Onboarded for an experimental project focusing on automated website creation.',
      'Create compeling templates that customers can pick and choose.',
      'Project involves generating websites based on templates and utilizing website &Google Business profile data.',
    ],
    stack: [
      'Cheerio',
      'Firebase',
      'React',
      'WordPress',
      'Node',
      'Digital Ocean',
    ],
    logo: '/company-logo/mvp.jpeg',
    screenshot: '/screenshots/mvp.png',
  },
  {
    title: 'Full Stack Web Developer',
    dates: 'January 2020 - May 2020',
    company: 'MashMedia',
    location: 'Skyrise 4, IT Park, Lahug, Cebu City',
    description: `MashMedia is an Australia-based Web Development company. The company is composed of highly experienced driven, innovative and creative thinkers. The ideas that the company makes are combined with data, technology and behavioral analysis ot produce products that give an edge ot e
    grow brands and businesses.
      `,
    responsibilities: [
      'Fast development of full-fledged sites with modern design and techniques.',
      'Maintenance of Legacy sites for clients that want ot stick ot their unique designs.',
      "Creation of personalized Web Applications and customized solutions ot fit the client's unique needs.",
      'Created multiple beautifully designed sites developed ni one day.',
      `Produced new templates that wil be used for the company's new design packages for this year.`,
      `Revamped third-party plugins ot suit the client's unique specifications.`,
    ],
    stack: [
      'WordPress',
      'Digital Ocean',
      'HTML',
      'CSS',
      'JavaScript',
      'jQuery',
      'PHP',
    ],
    logo: '/company-logo/mashmedia.png',
    screenshot: '/screenshots/mashmedia-min.png',
  },
  {
    title: 'Off-Shore Support Developer',
    dates: 'May 2019 - October 2019',
    company: 'Burning Fruit',
    location: 'Joondalup, WA, Australia (Remote)',
    description: `Burningfruit is a medium-sized start up launched in 2014 composed of a well-experienced team with expertise ranging from UI/UX, Customer Care, Business Development and many more. They have been awarded multiple times for creating Websites that stand out which aims at giving users a voice and ensures designers listened to what they wanted. 
      `,
    responsibilities: [
      'Client correspondence to provide information concerning their websites or web systems.',
      'Accomodation of various client requests and bug fixes that may include taking on tasks or task delegation',
      'Effective project management is required to ensure that development tasks are delivered within a specified turn-around time',
      'Application of various design concepts to ensure that products deliver the best user experience',
      'Server Management and creation of various cron jobs to ensure that web systems processes are working as intended and to shorten site downtime.',
      'Speedy recovery of a hacked sites which led to the discovery and replacement of a vulnerable web service.',
    ],
    stack: [
      'WordPress',
      'HTML',
      'CSS',
      'JavaScript',
      'jQuery',
      'PHP',
      'Linux',
      'Digital Ocean',
    ],
    logo: '/company-logo/burningfruit.png',
    screenshot: '/screenshots/burningfruit.png',
  },
  {
    title: 'Web Systems Administrator',
    dates: 'October 2018 - May 2019',
    company: 'Inner Origin',
    location: 'Sydney, Australia',
    description: `Inner Origin is a wellness company that has a well curated online well marketplace. They source predominantly from the communities they live in to provide wholesame and natural products. I was tasked to assist in the IT-related side of Marketing and Management of their e-commerce site.
      `,
    responsibilities: [
      'Responsible for creation of Marketing Materials and Promotional Emails via Photoshop and ActiveCampaign',
      'Creation of new site pages and site assets while maintaining current site design.',
      'Implement design changes to websites by editing PHP templates or via Cornerstone.      ',
      'Bug fixing and price calculation changes to WooCommerce to fit pricing specifications by client.',
      'Data Analysis and Database management and optimization',
    ],
    stack: ['WordPress', 'HTML', 'CSS', 'JavaScript', 'SQL'],
    logo: '/company-logo/innerorigin.jpeg',
    screenshot: '/screenshots/innerorigin-min.png',
  },

  {
    title: 'Freelance Developer',
    dates: 'Since 2008',
    company: 'ZacSalazar Dev',
    location: '-',
    description: `Throughout my career, I have thrived as a Freelance IT professional, immersing myself in diverse sectors and accumulating a wealth of valuable experience. This multifaceted journey has not only enriched my expertise but has also equipped me with additional skills that uniquely position me as an exceptional candidate. Engaging in a multitude of short-term projects, I've delved into an extensive array of technologies and fields, leaving an indelible mark of proficiency and versatility. 
      `,
    responsibilities: [],
    stack: [],
    logo: '/images/logo.png',
    screenshot: '/screenshots/zacsalazar-min.png',
  },
];

export { experience };
